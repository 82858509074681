<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Administração",
  data() {
    return {
      pageTitle: this.name,
    };
  },
});
</script>

<template>
  <router-view />
</template>
